import mixins from 'vue-typed-mixins';
import _ from 'lodash';

import StudyResultDialog from '@/user/components/study/dialog/result/StudyResultDialog.vue';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import StudyMixin from '@/user/mixins/StudyMixin';
import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';

import getQuestion from '@/master/transfer';


type Coordinate = {
  x: number;
  y: number;
};

type AnswerImage = {
  src: string;
  optionalCss?: string;
};

type Cell = {
  image: {
    baseSrcs: string[];
    answers: AnswerImage[]
  };
  lineDirs: Coordinate[]; // 進める向き
  distance?: number; // startセルからこのセルまでの距離の最小値
};

type TutorialData = {
  sectionNo: number,
  unitNo: number,
  assistances: Coordinate[][]
};

const ZENKAKU_SPACE = '　';
const START_IMAGE = 'Ｓ';
const GOAL_IMAGE = 'Ｇ';
const VIA_IMAGE = 'Ｏ';

const TUTORIAL_DATA: TutorialData[] = [
  {
    sectionNo: 1,
    unitNo: 1,
    assistances: [
      [{ x: 0, y: 1 }],
      [{ x: 1, y: 1 }]
    ]
  },
  {
    sectionNo: 1,
    unitNo: 2,
    assistances: [
      [{ x: 0, y: 1 }],
      [{ x: 1, y: 1 }],
      [{ x: 2, y: 1 }]
    ]
  },
  {
    sectionNo: 2,
    unitNo: 1,
    assistances: [
      [{ x: 0, y: 1 }],
      [{ x: 1, y: 1 }]
    ]
  },
  {
    sectionNo: 2,
    unitNo: 2,
    assistances: [
      [{ x: 1, y: 1 }],
      [{ x: 0, y: 1 }, { x: 0, y: 0 }],
      [{ x: 1, y: 0 }]
    ]
  }
];

export default mixins(
  RoutesMixin,
  StudyMixin,
  WindowAdjustMixin
).extend({

  name: 'Transfer',

  components: {
    StudyResultDialog,
  },

  data(): {
    cellLayout: Cell[][];
    start: Coordinate;
    goal: Coordinate;
    via: Coordinate[];
    destinations: Coordinate[][];
    answers: Coordinate[][];
    assistance: Coordinate | null;
  } {
    return {
      cellLayout: [],
      start: {
        x: 0,
        y: 0,
      },
      goal: {
        x: 0,
        y: 0,
      },
      via: [],
      destinations: [],
      answers: [],
      assistance: null
    };
  },

  computed: {
    stations(): Coordinate[] {
      return [this.start].concat(this.via, [this.goal]);
    },
    viaStations(): Coordinate[] {
      return this.answers.map(a => _.last(a)!);
    },
    cellStyle(): object {
      if (this.cellLayout.length === 0) return {};
      const rowCount = this.cellLayout[0].length;
      const width    = this.calculateRatio(390) / rowCount;
      const height   = this.calculateRatio(300) / rowCount;
      return {width: `${width}px`, height: `${height}px`};
    },
    isTutorial(): boolean {
      return TUTORIAL_DATA.some(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
    },
    assistances(): Coordinate[][] {
      const data = TUTORIAL_DATA.find(data => data.sectionNo === this.sectionNo && data.unitNo === this.unitNo);
      return data !== undefined ? data.assistances : [];
    }
  },

  created(): void {
    this.initializeStudyVariable();
    this.initializeStudyRequest();
    this.$_initializeCellLayout();
    this.answers.push([this.start]);
    this.$_createDestinations(this.start.x, this.start.y);
    this.$_updateCellLayout();
  },

  mounted(): void {
    this.startTimer();
  },

  methods: {

    addAnswer(x: number, y: number): void {
      const route = this.destinations.find(d => _.isEqual(_.last(d), { x, y }));
      if (route === undefined) return;
      const destination = _.last(route)!;
      const loopIndex = this.answers.findIndex(a => _.isEqual(_.last(a), destination));
      // 通常
      if (loopIndex === -1) this.answers.push(route);
      // ループ時
      else                  this.answers = this.answers.slice(0, loopIndex + 1);
      this.$_createDestinations(destination.x, destination.y);
      this.$_updateCellLayout();

      // ゴールの場合
      if (_.isEqual(destination, this.goal)) {
        const isCorrect = this.answers.length === this.cellLayout[this.goal.y][this.goal.x].distance! + 1;
        this.judgeScoreResult(isCorrect);
      }
    },

    $_initializeCellLayout(): void {
      const rows = getQuestion(this.sectionNo, this.unitNo).split('\n');
      const rowCount = rows.length / 2;
      for (let y = 0; y < rowCount; y++) {
        const cells: Cell[] = [];
        const stations = rows[y + rowCount].split('');
        rows[y].split('').forEach((line, x) => {
          const imgSrcs: string[] = [];
          const lineDirs: Coordinate[] = [];
          if (line !== ZENKAKU_SPACE) {
            imgSrcs.push(this.$_imgSrc(line));
            const lineDirDefinitions = {
              '━┏┗┣┳┻╋┝': {x: 1, y: 0},
              '━┓┛┳┫┻╋┥': {x: -1, y: 0},
              '┃┏┓┣┳┫╋┰': {x: 0, y: 1},
              '┃┗┛┣┫┻╋┸': {x: 0, y: -1},
            };
            for (const [key, value] of Object.entries(lineDirDefinitions)){
              if (key.includes(line)) lineDirs.push(value);
            }
            const station =  stations[x];
            if (station !== ZENKAKU_SPACE) {
              const coordinate = { x, y };
              switch (station) {
                case START_IMAGE:
                  this.start = coordinate;
                  break;
                case GOAL_IMAGE:
                  this.goal = coordinate;
                  break;
                case VIA_IMAGE:
                  this.via.push(coordinate);
                  imgSrcs.push(this.$_imgSrc(station));
                  break;
              }
            }
          }
          cells.push({
            image: {
              baseSrcs: imgSrcs,
              answers: [],
            },
            lineDirs,
          });
        });
        this.cellLayout.push(cells);
      }

      // スタートからの各座標への距離を設定
      this.$_setDistanceOfStartToCell();
    },

    /*
      startからgoalまでの最短値を求める手順
      1.coordinatesToGo配列にスタートのセルの座標を格納。
      2.coordinatesToGoから座標を取得してcurrentCellを取得するループを開始。
      3.currentCellからlineDirsを取得してnextCellを取得。
      4.nextCellがdistance === undefined(未探索)、もしくはdistanceの最短値が更新できれば、
      distanceの値を更新し、nextCellの座標をcoordinatesToGoリストに追加する。
      5.coordinatesToGoの配列が空になった時、ゴールの座標を持つcellLayoutのdistanceの値が最短値。
    */
    $_setDistanceOfStartToCell(): void {
      const coordinatesToGo: Coordinate[] = [];
      this.cellLayout[this.start.y][this.start.x].distance = 0;
      coordinatesToGo.push({x: this.start.x, y: this.start.y});
      while (coordinatesToGo.length > 0) {
        const currentCoordinate = coordinatesToGo.shift()!;
        const currentCell = this.cellLayout[currentCoordinate.y][currentCoordinate.x];
        const currentCellDistance = currentCell.distance!;
        currentCell.lineDirs.forEach(lineDir => {
          const nextCoordinate = {
            x: currentCoordinate.x + lineDir.x,
            y: currentCoordinate.y + lineDir.y,
          };
          const nextCell = this.cellLayout[nextCoordinate.y][nextCoordinate.x];
          const isBlank = this.stations.find(station => _.isEqual(station, nextCoordinate)) === undefined;
          // 次のセルが未探索
          if (nextCell.distance === undefined) {
            nextCell.distance = currentCellDistance;
            if (!isBlank) nextCell.distance += 1;
            if (!_.isEqual(this.goal, nextCoordinate)) coordinatesToGo.push(nextCoordinate);
          }
          // 次のセルが探索済み
          else {
            if (isBlank && currentCellDistance < nextCell.distance) {
              nextCell.distance = currentCellDistance;
              coordinatesToGo.push(nextCoordinate);
            } else if (!isBlank && currentCellDistance + 1 < nextCell.distance) {
              nextCell.distance = currentCellDistance + 1;
              if (!_.isEqual(this.goal, nextCoordinate)) coordinatesToGo.push(nextCoordinate);
            }
          }
        });
      }
    },

    $_createDestinations(startX: number, startY: number): void {
      const create = (x: number, y: number, lines: Coordinate[]): void => {
        const start = this.cellLayout[y][x];
        for (const coordinate of start.lineDirs) {
          const nextCoordinate = {
            x: x + coordinate.x,
            y: y + coordinate.y,
          };
          if (_.find(lines, nextCoordinate) !== undefined) continue;
          const cloneLines = _.cloneDeep(lines);
          cloneLines.push(nextCoordinate);
          if (_.find(this.stations, nextCoordinate) !== undefined) {
            this.destinations.push(cloneLines);
            continue;
          }

          create(nextCoordinate.x, nextCoordinate.y, cloneLines);
        }
      };

      this.destinations = [];
      // 再帰的に移動可能経路作成
      create(startX, startY, []);
      // ゴール以外の通過駅も移動可能
      this.viaStations.filter(s => !_.isEqual(s, this.goal))
        .forEach(s => this.destinations.push([s]));
    },

    $_updateCellLayout(): void {
      // 回答画像初期化
      this.cellLayout.forEach(cells => {
        cells.forEach(cell => {
          cell.image.answers = [];
        });
      });

      // 回答画像再構成
      // 通過線路
      const pushAnswerImage = (answerImages: AnswerImage[], name: string, optionalCss?: string): void => {
        answerImages.push({
          src: this.$_imgSrc(name),
          optionalCss,
        });
      };
      const pushLinePassImg = (c1: Coordinate, c2: Coordinate, answerImages: AnswerImage[]): void => {
        let imageName = '';
        if      (c1.x > c2.x) imageName = '┝黄';
        else if (c1.x < c2.x) imageName = '┥黄';
        else if (c1.y > c2.y) imageName = '┰黄';
        else if (c1.y < c2.y) imageName = '┸黄';
        pushAnswerImage(answerImages, imageName);
      };
      const routes = _.flatten(this.answers);
      routes.forEach((current, i) => {
        const prev = routes[i - 1];
        const next = routes[i + 1];
        const cellAnswerImage = this.cellLayout[current.y][current.x].image.answers;

        // スタート以外
        if (prev !== undefined) pushLinePassImg(prev, current, cellAnswerImage);
        // ゴール以外
        if (next !== undefined) pushLinePassImg(next, current, cellAnswerImage);
      });
      // 通過駅
      this.viaStations.forEach((s, i) => {
        const cellAnswerImage = this.cellLayout[s.y][s.x].image.answers;
        let stationName = String(i);
        if      (_.isEqual(s, this.start)) stationName = START_IMAGE;
        else if (_.isEqual(s, this.goal))  stationName = GOAL_IMAGE;
        pushAnswerImage(cellAnswerImage, stationName);
        if (i === this.viaStations.length - 1) {
          const rowCount = this.cellLayout[0].length;
          if (stationName !== GOAL_IMAGE) {
            pushAnswerImage(this.cellLayout[this.goal.y][this.goal.x].image.answers, GOAL_IMAGE);
          }
          pushAnswerImage(cellAnswerImage, 'リス', `squirrelImgRow${rowCount}`);
        }
      });

      // チュートリアルの場合は回答補助設定
      if (this.isTutorial) this.$_setAssistance();
    },

    $_imgSrc(name: string): string {
      return require(`@/assets/img/study/transfer/${name}.png`);
    },

    $_setAssistance(): void {
      this.assistance = null;
      for (let i = 0; i < this.assistances.length; i++) {
        const assistance = this.assistances[i];
        const answer     = this.answers[i];
        // 回答が無い場合は入力補助に設定
        if (answer === undefined) {
          this.assistance = assistance[assistance.length - 1];
          return;
        }
        // 入力補助から外れた場合はスタートを設定
        if (!_.isEqual(assistance, answer)) {
          this.assistance = this.assistances[0][0];
          return;
        }
      }
    }

  },

});
